import React, { useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import "./QuestionnaireForm.scss";

import ProgressBar from "components/UI/ProgressBar/ProgressBar";
import MyButton from "components/UI/Button/MyButton";
import nextBtn from "assets/images/next_btn.svg";

import Range from "./FormSteps/Range";
import Multiselect from "./FormSteps/Multiselect";
import Dropdown from "./FormSteps/Dropdown";
import RadioStep from "./FormSteps/RadioStep";
import TextStep from "./FormSteps/TextStep";
import { questionnaireFormValidationSchema } from "../ValidationSchemas/QuestionnaireFormValidationSchema";
import Modal from "components/UI/Modal/Modal";
import SuccessModalContent from "./SuccessModalContent/SuccessModalContent";
import { useActions } from "hooks/useActions";
import { API_BASE_URL } from "constants/api";
import ResponseErrorHandler from "helpers/ResponseErrorHandler";
import { removeObjectEmptyValues } from "helpers/removeObjectEmptyValues";
import InformativePanel from "./FormSteps/InformativePanel";

const QuestionnaireForm = ({ questions, observerCode, observerToken }) => {
  // observerCode and observerToken are used when it is the questionnaire for the observer
  const { state } = useLocation();
  const [steps, setSteps] = useState([]);
  const [formStep, setFormStep] = useState(steps[0]);
  const [initialValues, setInitialValues] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [blockSubmit, setBlockSubmit] = useState(false);
  let isLastStep = activeStep === steps.length - 1;

  const [questionNumber, setQuestionNumber] = useState(0);

  const { userToken } = useSelector((state) => state.auth);
  const { getSingleCampaign } = useActions();

  const [modalActive, setModalActive] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleNavigateToCampaignPage = (id) => {
    setModalActive(false);
    navigate(`/campaign/${id}`);
    getSingleCampaign({ userToken, id });
  };

  const renderStepsContent = (item, values, index) => {
    const question = index + '. ' + item.question;
    switch (item.type) {
      case "range":
        return (
          <Field
            name={item.id}
            min_scale_text={item.min_scale_text}
            max_scale_text={item.max_scale_text}
            component={Range}
            question={question}
            values={values}
            item={item}
            count={item.max_scale_variants}
          />
        );
      case "multiselect":
        return (
          <Field
            values={values}
            name={item.id}
            options={item.options}
            component={Multiselect}
            question={item.question}
            item={item}
          />
        );
      case "dropdown":
        return (
          <Field
            values={values}
            name={item.id}
            options={item.options}
            component={Dropdown}
            question={item.question}
            item={item}
          />
        );
      case "radio":
        return (
          <Field
            values={values}
            name={item.id}
            options={item.options}
            component={RadioStep}
            question={item.question}
            item={item}
          />
        );
      case "text":
        return (
          <Field
            placeholder='Enter your answer...'
            name={item.id}
            component={TextStep}
            question={item.question}
          />
        );
      case "informative_panel":
        return <InformativePanel />;
      default:
        break;
    }
  };

  const _submitForm = async (values, actions) => {
    if (isLastStep) setBlockSubmit(true);

    let formData = {
      answers: [],
    };

    for (const key in removeObjectEmptyValues(values)) {
      const newValues = {};
      newValues["question_id"] = Number(key);
      newValues["answer"] = values[key].toString();

      formData.answers.push(newValues);
    }

    if (observerCode && observerToken) {
      const { data } = await axios({
        method: "post",
        url: `${API_BASE_URL}observer/questionnaire?observer_code=${observerCode}`,
        headers: {
          Authorization: `Bearer ${observerToken}`,
        },
        accept: "application/json",
        data: formData,
      })
        .then((data) => data)
        .catch((error) => error.response);

      if (data.status === "Success") {
        navigate(`/observer/questionnaire?observer_code=${observerCode}`);
      }

      if (data?.status === "Error") {
        const err = new ResponseErrorHandler(data);

        console.log(err.getValidationErrorMessages());
      }
    } else {
      const { data } = await axios({
        method: "post",
        url: `${API_BASE_URL}student/campaigns/${id}/questionnaire`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        accept: "application/json",
        data: formData,
      })
        .then((data) => data)
        .catch((error) => error.response);

      if (data.status === "Success") {
        setModalActive(true);
      }

      if (data?.status === "Error") {
        const err = new ResponseErrorHandler(data);

        console.log(err.getValidationErrorMessages());
      }
    }

    actions.setSubmitting(false);
  };

  const _handleSubmit = (values, actions) => {
    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      setFormStep(steps[activeStep + 1]);
      setQuestionNumber(questionNumber + 1);

      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const _handleBack = () => {
    setActiveStep(activeStep - 1);
    setFormStep(steps[activeStep - 1]);
    setQuestionNumber(questionNumber - 1);

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setSteps(questions);

    // initial used to create initialValues of the form and add questions id as the name of the form fields
    const initial = {};

    for (let i in questions) {
      let obj = questions[i];
      initial[obj.id] = "";
    }

    setInitialValues(initial);
    setFormStep(questions[0]);
  }, [questions]);

  return (
    <section className='questionnaire-form'>
      <ProgressBar total={steps.length - 1} current={activeStep} />
      {initialValues && formStep && (
        <Formik
          initialValues={initialValues}
          validationSchema={questionnaireFormValidationSchema(formStep)}
          onSubmit={_handleSubmit}
        >
          {({ isSubmitting, values, errors, ...props }) => {
            return (
              <Form id='questionnaire' className='form__container'>

                {formStep && renderStepsContent(formStep, values, questionNumber)}
                <div className='form__buttons'>
                  {activeStep !== 0 && (
                    <MyButton
                      onClick={() => _handleBack()}
                      className='questionnaire-back'
                      type='button'
                    >
                      <img src={nextBtn} alt='nextBtn' />
                      Back
                    </MyButton>
                  )}
                  <div>
                    <MyButton
                      type='submit'
                      className='questionnaire-submit'
                      disabled={blockSubmit}
                      onClick={
                        Object.keys(errors).length > 0
                          ? null
                          : () => window.scrollTo(0, 0)
                      }
                    >
                      {formStep.type === "informative_panel"
                        ? state?.language === "it"
                          ? "Continua"
                          : "Continue"
                        : isLastStep
                        ? "Submit"
                        : "Next"}
                      {!isLastStep && formStep.type !== "informative_panel" && (
                        <img src={nextBtn} alt='nextBtn' />
                      )}
                    </MyButton>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
      <Modal
        active={modalActive}
        setActive={() => handleNavigateToCampaignPage(id)}
      >
        <SuccessModalContent
          setActive={() => handleNavigateToCampaignPage(id)}
        />
      </Modal>
    </section>
  );
};

export default QuestionnaireForm;
