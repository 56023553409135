import React from 'react';

const ParticipantsInfo = ({ language }) => {
  if (language === 'it') {
    return (
      <>
        <p className='middle'>
          Ti verranno fatte una serie di domande alle quali dovrai rispondere in modo rapido ed usando solo l’intuito.
        </p>
        <p className='middle'>
          Non soffermarti troppo a lungo a valutare le alternative tra le risposte. Se nessuna alternativa ti soddisfa, scegli la risposta che più si avvicina a quello che pensi.
        </p>
        <p className='middle'>
          Rispondi alle domande il più sinceramente possibile.
        </p>
        <p className='middle'>
            <b>Nota terminologica importante:</b> Il questionario è aggiornato alle più recenti ricerche sullo sviluppo delle organizzazioni e delle carriere. Infatti, esso tiene conto del passaggio dall’impiego a tempo pieno ad un tipo di carriera “a portafoglio”, così come delle nuove professioni e delle nuove modalità lavorative legate allo sviluppo tecnologico. La terminologia utilizzata nel questionario tiene conto di questi sviluppi.
        </p>
        <p className='middle'>
            <b>“Io”</b> si riferisce a come ti vedi in quanto “persona”, dal punto di vista professionale e privato.
        </p>
        <p className='middle'>
            <b>“Contatti interpersonali”</b> sono le persone con cui lavori ogni giorno. Se sei un dipendente, può trattarsi del tuo team, dei tuoi colleghi e del tuo capo. Se sei un freelance o un imprenditore, dei tuoi colleghi e collaboratori.
        </p>
        <p className='middle'>
            <b>“Comunità”</b> se sei un dipendente può trattarsi della tua azienda o organizzazione per cui stai lavorando. Se sei un freelance o un imprenditore, potrebbero essere le diverse aziende per cui lavori o i clienti con cui collabori.
        </p>
        <p className='middle'>
            <b>“Ecosistema”</b> può essere il settore in cui opera la tua azienda o i diversi ambiti in cui lavori, al difuori dell’azienda e/o dell’organizzazione.
        </p>
      </>
    );
  }

  return (
    <>
      <p>
        You will be asked a set of questions. Please answer as intuitively and
        quickly as possible. Don't overthink the choices. If none of the choices
        apply fully, select the closest that applies.
      </p>
      <p className='middle'>
        Please answer to the questions as truthfully as possible.
      </p>
      <p>
        <b>Important notice on terminology:</b> This questionnarie considers the
        latest developments in the field of careers and organusational
        developments. As such is taking into account (e.g. the shifts into
        portfolio careers versus full-time employment or new technology related
        professions and ways of working). The terminology used in this
        questionnaire is adopted to these new organisational developments.
        <b>"Self"</b> refers to who you see yourself to be as person (both
        professionally and privately). <b>"Interpersonal Connections"</b> are
        the people you work with on a daily basis. If you are employed this can
        be your team, if you are a freelancer/entrepreneur this can be your
        colleagues. <b>"Community"</b> if you are employed this can be your
        organization, if you are freelancer/entrepreneur it might be the
        different companies that you are part of or clients you work with.
        <b>"Ecosystem"</b> can be the industry your company is part of or areas
        you are working in, these areas go beyond just organisations.
      </p>
    </>
  );
};

export default ParticipantsInfo;
